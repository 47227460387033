<template>
  <div v-if="isEdit" class="fr-setting">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <SwitchSetter v-model="paramSrc" :data="frConfig.paramSource" :enabled="false" />
      <div class="line"></div>
      <SwitchSetter v-model="setting.channel[0].fr.isEnabled" :data="frConfig.isEnabled" :enabled="canSetting" />
      <SliderSetter v-model="setting.channel[0].fr.faceSizeRatio" :data="frConfig.faceSizeRatio" :enabled="canSetting"  />
      <div class="expert-mode" @click="isEditExpertMode = !isEditExpertMode">
        <img v-if="isEditExpertMode" src="@/assets/icons/Arrow_down.svg" alt="">
        <img v-else class="arrow-right" src="@/assets/icons/Arrow_down.svg" alt="">
        <img src="@/assets/icons/graduation-cap.svg" alt="">
        <span>{{ $t('ai_expert_mode')/*專家模式*/ }}</span>
      </div>
      <template v-if="isEditExpertMode">
        <SwitchSetter v-model="setting.channel[0].fr.detectMode" :data="frConfig.detectMode" :enabled="canSetting" />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_full_face_mode')/*全臉模式*/ }}</div>
        <SliderSetter 
          v-model="setting.channel[0].fr.eventScore" 
          :data="frConfig.eventScore" 
          @change="onEventScoreChange"
          :enabled="canSetting" 
        />
        <SliderSetter 
          v-model="setting.channel[0].fr.candidateScore" 
          :data="frConfig.candidateScore"
          @change="onCandidateScoreChange"
          :enabled="canSetting" 
        />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_half_face_mode')/*半臉模式*/ }}</div>
        <SliderSetter 
          v-model="setting.channel[0].fr.eventScoreWithMask" 
          :data="frConfig.eventScoreWithMask"
          @change="onEventScoreWithMaskChange"
          :enabled="canSetting" 
        />
        <SliderSetter 
          v-model="setting.channel[0].fr.candidateScoreWithMask" 
          :data="frConfig.candidateScoreWithMask"
          @change="onCandidateScoreWithMaskChange"
          :enabled="canSetting" 
        />
        <div class="line"></div>
        <SliderSetter v-model="setting.channel[0].fr.detectThresholdLevel" :data="frConfig.detectThresholdLevel" :enabled="canSetting" />
        <SwitchSetter v-model="setting.deviceConfig.fr.eventPostMode" :data="frConfig.eventPostMode" :enabled="canSetting" />
        <SelectSetter v-model="notifyFilterMode" :data="frConfig.notifyFilterMode" :enabled="canSetting" />
        <SliderSetter v-model="setting.deviceConfig.fr.eventDuration" :data="frConfig.eventDuration" :enabled="canSetting" />
        <SliderSetter v-model="setting.deviceConfig.fr.repostDataStoreLimit" :data="frConfig.repostDataStoreLimit" :enabled="canSetting" />
      </template>
    </div>
  </div>
  <div v-else class="fr-setting view">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <ConfigItem v-model="currDefaultSetting" :data="frConfig.paramSource" :showTooltip="false" />
      <ConfigItem v-model="setting.channel[0].fr.isEnabled" :data="frConfig.isEnabled" :showTooltip="false" />
      <ConfigItem v-model="setting.channel[0].fr.faceSizeRatio" :data="frConfig.faceSizeRatio" />
      <div class="expert-mode" @click="isViewExpertMode = !isViewExpertMode">
        <img v-if="isViewExpertMode" src="@/assets/icons/Arrow_down.svg" alt="">
        <img v-else class="arrow-right" src="@/assets/icons/Arrow_down.svg" alt="">
        <img src="@/assets/icons/graduation-cap.svg" alt="">
        <span>{{ $t('ai_expert_mode')/*專家模式*/ }}</span>
      </div>
      <template v-if="isViewExpertMode">
        <ConfigItem v-model="setting.channel[0].fr.detectMode" :data="frConfig.detectMode" />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_full_face_mode')/*全臉模式*/ }}</div>
        <ConfigItem v-model="setting.channel[0].fr.eventScore" :data="frConfig.eventScore" />
        <ConfigItem v-model="setting.channel[0].fr.candidateScore" :data="frConfig.candidateScore" />
        <div class="line"></div>
        <div class="mode">{{ $t('ai_half_face_mode')/*半臉模式*/ }}</div>
        <ConfigItem v-model="setting.channel[0].fr.eventScoreWithMask" :data="frConfig.eventScoreWithMask" />
        <ConfigItem v-model="setting.channel[0].fr.candidateScoreWithMask" :data="frConfig.candidateScoreWithMask" />
        <div class="line"></div>
        <ConfigItem v-model="setting.channel[0].fr.detectThresholdLevel" :data="frConfig.detectThresholdLevel" />
        <ConfigItem v-model="setting.deviceConfig.fr.eventPostMode" :data="frConfig.eventPostMode" />
        <ConfigItem v-model="notifyFilterMode" :data="frConfig.notifyFilterMode" />
        <ConfigItem v-model="setting.deviceConfig.fr.eventDuration" :data="frConfig.eventDuration" />
        <ConfigItem v-model="setting.deviceConfig.fr.repostDataStoreLimit" :data="frConfig.repostDataStoreLimit" />
      </template>
      <div class="line"></div>
      <div class="time">
        <span>{{ $t('ai_updated_time')/*更新時間*/ }}</span>
        <span>{{ formatTime(currentSetting.updateTime) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { frConfig } from '@/config/configFr.js'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'FrSetting',
  components: {
    SwitchSetter: () => import('@/components/AiBox/base/SwitchSetter.vue'),
    SliderSetter: () => import('@/components/AiBox/base/SliderSetter.vue'),
    SelectSetter: () => import('@/components/AiBox/base/SelectSetter.vue'),
    ConfigItem: () => import('@/components/AiBox/base/ConfigItem.vue'),
  },
  data() {
    return {
      frConfig,
      globalConfig: {},
      userConfig: {},
      isEditExpertMode: false,
      isViewExpertMode: false,
    }
  },
  computed: {
    ...mapState('aibox', ['isEdit', 'isEditRoi']),
    ...mapState('recgFr', [ 
      'currDefaultSetting', 
      'defaultSetting', 
      'userSetting', 
      'faceSizeRatio',
      'frNotifyFilterMode',
    ]),
    ...mapGetters('recgFr', ['currentSetting']),
    paramSrc: {
      get() {
        return this.currDefaultSetting
      },
      set(val) {
        this.onUpdateCurrDefaultSetting(val)
      },
    },
    canSetting() {
      // defaultSetting = 0: userSetting 設備客製/可編輯, 1: defaultSetting 系統預設/不可編輯
      return this.currDefaultSetting === 0 && !this.isEditRoi
    },
    setting: {
      get() {
        return this.currentSetting.setting
      },
      set(data) {
        this.updateSetting(data)
      },
    },
    config() {
      return this.currDefaultSetting === 1 ? this.globalConfig : this.userConfig
    },
    scrollFaceRatio() {
      return this.isConfigReady ? 
        this.config.setting.channel[0].fr.faceSizeRatio : null
    },
    notifyFilterMode: {
      get() {
        return this.frNotifyFilterMode
      },
      set(val) {
        this.updateFrNotifyFilterMode(val)
      },
    },
  },
  watch: {
    faceSizeRatio(val) {
      if (this.isConfigReady)
        this.config.setting.channel[0].fr.faceSizeRatio = val
    },
    scrollFaceRatio(val) {
      this.updateFaceSizeRatio(val)
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('aibox', ['updateParamAiboxPortal', 'updateShowAiboxPortal']),
    ...mapMutations('recgFr', [
      'updateSetting', 
      'updateFaceSizeRatio', 
      'updateFrNotifyFilterMode',
    ]),
    ...mapActions('recgFr', ['onUpdateCurrDefaultSetting']),
    formatTime,
    onEventScoreChange(val) {
      if (val < this.setting.channel[0].fr.candidateScore) {
        let newVal = val - 5 < this.frConfig.candidateScore.min ? this.frConfig.candidateScore.min : val - 5
        const adjustData = {
          adjustKey: 'candidateScore',
          adjustScore: newVal,
        }
        
        this.updateParamAiboxPortal({ info: 'frScoreAdjust', action: null, data: adjustData })
        this.updateShowAiboxPortal(true)
        this.setting.channel[0].fr.candidateScore = newVal
      }
    },
    onCandidateScoreChange(val) {
      if (val > this.setting.channel[0].fr.eventScore) {
        let newVal = val + 5 > this.frConfig.eventScore.max ? this.frConfig.eventScore.max : val + 5
        const adjustData = {
          adjustKey: 'eventScore',
          adjustScore: newVal,
        }
        
        this.updateParamAiboxPortal({ info: 'frScoreAdjust', action: null, data: adjustData })
        this.updateShowAiboxPortal(true)
        this.setting.channel[0].fr.eventScore = newVal
      }
    },
    onEventScoreWithMaskChange(val) {
      if (val < this.setting.channel[0].fr.candidateScoreWithMask) {
        let newVal = val - 5 < this.frConfig.candidateScoreWithMask.min ? this.frConfig.candidateScoreWithMask.min : val - 5
        const adjustData = {
          adjustKey: 'candidateScoreWithMask',
          adjustScore: newVal,
        }
        
        this.updateParamAiboxPortal({ info: 'frScoreAdjust', action: null, data: adjustData })
        this.updateShowAiboxPortal(true)
        this.setting.channel[0].fr.candidateScoreWithMask = newVal
      }
    },
    onCandidateScoreWithMaskChange(val) {
      if (val > this.setting.channel[0].fr.eventScoreWithMask) {
        let newVal = val + 5 > this.frConfig.eventScoreWithMask.max ? this.frConfig.eventScoreWithMask.max : val + 5
        const adjustData = {
          adjustKey: 'eventScoreWithMask',
          adjustScore: newVal,
        }
        
        this.updateParamAiboxPortal({ info: 'frScoreAdjust', action: null, data: adjustData })
        this.updateShowAiboxPortal(true)
        this.setting.channel[0].fr.eventScoreWithMask = newVal
      }
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.fr-setting {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    display: flex;
    align-items: center;
    @include font_style(px2rem(16), px2rem(24), #FFE99F, 700);
    margin-bottom: 8px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      @include filter_FFE99F;
    }
  }
  
  .content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px 4px 12px 12px;
    box-sizing: border-box;
    overflow-y: scroll;
    background: #151B35f5;
    border-radius: 8px;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #ffffff80;
    }
    .expert-mode {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: #FFE99F;
      cursor: pointer;
      img {
        width: 16px;
        margin-right: 12px;
        @include filter_FFE99F;
        &.arrow-right {
          transform: rotate(-90deg);
        }
      }
    }
    .mode {
      color:#ffffff80;
    }
  }

  &.view {
    .title {
      color: #FFE99F;
      font-weight: 500;
      img {
        filter: none;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      background: #39425D;


      .time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff80;
        font-size: 14px;
        span:first-child {
          font-size: 16px;
        }
      }
    }
  }
}
</style>